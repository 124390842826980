@import 'src/assets/styles/colors.scss';

.react-select__control {
  padding: 7px 10px 7px 10px;
  color: #333333;
  background-color: #ffffff !important;
  border: solid 1px #ced0da !important;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;

  .react-select__indicators {
    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      color: $black;
    }
  }

  .react-select__menu {
    z-index: 2;
    .react-select__menu-list {
      font-size: 14px;
    }
  }

  .react-select__multi-value {
    background-color: #dbebff;
    border-radius: 5%;

    .react-select__multi-value__label {
      font-size: 17px;
      color: #2771cd;
    }

    .react-select__multi-value__remove {
      svg {
        color: #2771cd;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.box-select-wrapper {
  display: flex;
  flex-wrap: wrap;

  .box-select-item-wrapper {
    min-width: 90px;
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
}
