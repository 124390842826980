@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
