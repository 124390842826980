@import 'src/assets/styles/colors.scss';

.progressRing {
  animation: spin 2s linear infinite;

  .progressRingCircle {
    stroke-dasharray: 4 4;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform-origin: center;
  }
}

.Button {
  border-radius: 4px;
  border: none;
  padding: 15px 30px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.Button:focus {
  outline: 0;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.primary {
  background: $primary_blue;
}

.error,
.red {
  background: $red;
}

.dark {
  background: $dark_blue;
}

.secondary {
  background: #2a76df;
}

.brand-green {
  background: #51a454;
}

.white {
  background: #ffffff;
  color: #333;
  font-size: 14px;
}

.charcoal-grey {
  background: $charcoal_grey;
}

.turquoise {
  background: $turquoise_blue;
}

.orange-gradient {
  background-image: linear-gradient(280deg, #f56185, #fea76c);
}

.blue {
  background: $blue;
}
