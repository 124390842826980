$primary_blue: #188bc0;
$silver: #dee0e3;
$dark_blue: #30596e;
$charcoal_grey: #3b3e43;
$black: #212529;
$red: #c01818;
$pale_grey: #ebecee;
$white: #ffffff;
$blue: #188bc0;
$green: #51a454;
$turquoise_blue: #059db5;
$orange-gradient: linear-gradient(280deg, #f56185, #fea76c);
$light_blue: #0095d4;
$light_grey: #65676b;
$dark_grey: #212529;
