@import 'src/assets/styles/colors.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(48, 89, 110, 0.75);
  z-index: 11; // Navigation is 10;

  display: flex;
  align-items: center;
  justify-content: center;

  &.blurredBackdrop {
    backdrop-filter: blur(2px);
  }

  .container {
    width: 800px;
    position: relative;
    border-radius: 4px;

    max-height: 100%;
    overflow-y: auto;

    .close {
      top: 22px;
      right: 27px;
      position: absolute;

      cursor: pointer;

      font-size: 18px;
      font-family: 'Material Icons';
    }

    .header,
    .footer {
      display: flex;
      align-items: center;
      background-color: #eaeef3;

      box-sizing: border-box;
    }

    .header {
      height: 77px;
      padding-left: 40px;

      border-radius: 4px 4px 0 0;

      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
    }

    .content {
      background-color: white;
      padding: 20px 40px 30px 40px;
    }

    .footer {
      padding: 30px;
      border-radius: 0 0 4px 4px;
    }
  }
}
