@import 'src/assets/styles/_media_variables.scss';
@import 'src/assets/styles/colors.scss';

.modalWrapper > .modalDim {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#333333, 0.8);
  overflow-y: auto;
  z-index: 9999;
  display: flex;
  justify-content: center;
}

.modalWrapper > .modalDim > .modal {
  position: absolute;
  top: 5%;
  width: 830px;
  z-index: 9999;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 20vh;

  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    box-sizing: border-box;
  }
}

.modalWrapper > .modalDim > .modal > .header {
  position: relative;
}
.modalWrapper > .modalDim > .modal > .header:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  border-top: 1px solid $silver;

  @media screen and (max-width: $screen-xs-max) {
    left: 15px;
    right: 15px;
  }
}

.modalWrapper > .modalDim > .modal > .header.withoutHeaderBorder:after {
  border-top: 0px;
}

.modalWrapper > .modalDim > .modal > .header > .heading {
  padding: 20px 30px;

  @media screen and (max-width: $screen-xs-max) {
    padding: 15px;
  }
}
.modalWrapper > .modalDim > .modal > .content {
  padding: 0 30px;
  @media screen and (max-width: $screen-xs-max) {
    padding: 0 15px;
  }
}
.modalWrapper > .modalDim > .modal > .header > .modalClose {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  cursor: pointer;
  @media screen and (max-width: $screen-xs-max) {
    top: 15px;
    right: 15px;
  }
}
.footer {
  border-top: 1px solid $silver;
  margin: 10px 0px;
  padding: 20px 0px;
}
