@import '_fonts.scss';
@import 'colors.scss';
@import '_media_variables.scss';

body {
  margin: 0;
  color: $black;
  font-family: Barlow;
}

* {
  font-family: Barlow;
}

.row {
  margin: 0;
  width: 100%;
}

.container {
  width: 1170px !important;

  @media screen and (max-width: $screen-md-max) {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
}

.large-container {
  width: 1300px !important;

  @media screen and (max-width: $screen-md-max) {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
}

.small-container {
  width: 770px !important;

  @media screen and (max-width: 770px) {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
}

.center-wrapper {
  display: flex;
  justify-content: center;
}

.help-text {
  line-height: 1.63;
  color: $charcoal_grey;
}

.page-title {
  color: #333333;
  font-weight: 500;
  font-size: 36px;
  margin: 0;

  @media screen and (max-width: $screen-sm-max) {
    font-size: 32px;
  }

  @media screen and (max-width: $screen-xs-max) {
    font-size: 26px;
  }
}
.page-title-label {
  color: $dark_blue;
  font-size: 16px;
}

.secondary-page-title {
  color: #333333;
  font-weight: 500;
  font-size: 32px;

  @media screen and (max-width: $screen-xs-max) {
    font-size: 26px;
  }
}

.form-label {
  font-size: 16px;
  color: #4a4a4a;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 500;

  &.dark-label {
    color: #ced0da;
    font-size: 16px;
  }
}
.DetailMessagingimg {
  width: 100%;
}
.DetailMessagingImgContainer {
  max-width: 700px;
  margin: 0 auto;
}
.InvestorMessagingImgContainer {
  margin-top: 30px;
  max-width: 700px;
  margin: 0 auto;
}
.InvestorMessagingImgContainer .Messagingimg {
  padding-top: 30px;
  max-width: 700px;
}
.MessagingImgContainer {
  max-width: 700px;
  margin: 0 auto;
}

.form-group {
  text-align: left;
  padding-bottom: 15px;

  .input-info {
    font-size: 16px;
    line-height: 1.13;
    text-align: left;
    color: #ced0da;
    font-family: CerebriSansItalic;
    padding-bottom: 15px;
    margin-top: 0;
    padding-top: 10px;
  }
}
.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 34px;

  input {
    display: none;
  }
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: green;
}

input:disabled + .slider {
  background-color: rgba(0, 172, 65, 0.41);
}

input:focus + .slider {
  box-shadow: 0 0 1px green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

.secondary-title {
  font-weight: 500;
  font-size: 22px;
  color: $black;
}

.remove-xs {
  @media only screen and (max-width: $screen-xs-max) {
    display: none !important;
  }
}

.remove-sm-min {
  @media only screen and (min-width: $screen-sm-min) {
    display: none !important;
  }
}

.remove-sm {
  @media only screen and (max-width: $screen-sm-max) {
    display: none !important;
  }
}

.remove-md-min {
  @media only screen and (min-width: $screen-md-min) {
    display: none !important;
  }
}

.remove-md {
  @media only screen and (max-width: $screen-md-max) {
    display: none !important;
  }
}

.remove-lg {
  @media only screen and (min-width: $screen-lg-min) {
    display: none !important;
  }
}

.react-select__menu {
  .react-select__menu-list {
    font-size: 14px;
  }
}

.reset-label {
  font-size: 15px;
  color: $charcoal_grey;
  cursor: pointer;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  align-content: center;
}

.half-row {
  grid-column-start: span 1;

  @media screen and (max-width: $screen-xs-max) {
    grid-column-start: span 2;
  }
}
.full-row {
  grid-column-start: span 2;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

.selected-navigation-item {
  color: $primary_blue;
}

.form-control {
  display: block;
  padding: 16px 10px 16px 10px;
  color: #333333;
  background-color: #ffffff !important;
  font-size: 16px;
  border: solid 1px #ced0da;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    color: $charcoal_grey;
    font-size: 16px;
  }
}

.invalid-feedback {
  font-size: 16px;
  line-height: 1.9;
  color: #df3d3d;

  &::first-letter {
    text-transform: uppercase;
  }
}

*:focus {
  outline: 0;
}
