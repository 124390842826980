@import 'src/assets/styles/_media_variables.scss';
@import 'src/assets/styles/colors.scss';

.fullscreenModalContainer {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
  @media screen and (max-width: $screen-xs-max) {
    padding: 0;
    height: 100vh;
  }
}
.modalContainer {
  background-color: $pale_grey;
  height: 100%;
  min-height: 750px;

  @media screen and (max-width: $screen-xs-max) {
    min-height: 100vh;
    height: auto;
  }
}
.modalHeadline {
  display: flex;
  justify-content: space-between;
}

.unissuLogo {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 2;

  img {
    @media screen and (max-width: $screen-xs-max) {
      height: 30px;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    padding-top: 15px;
  }
}
.modalClose {
  z-index: 2;
  padding: 15px 35px 0 0;
  position: absolute;
  right: 0;
  cursor: pointer;

  @media screen and (max-width: $screen-xs-max) {
    padding: 15px 15px 0 0;
  }
}
.closeBtn {
  font-size: 30px;
}
.content {
  height: 100%;
}
