@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/_media_variables.scss';

.modalDim {
  position: fixed;
  overflow-x: auto;
  background-color: rgba(#333333, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  animation: fadeIn ease 1s;

  @media screen and (max-width: $screen-sm-max) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalContainer {
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: $screen-xs-max) {
      height: 85%;
    }

    .modalContent {
      width: 100%;
      height: 320px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media screen and (min-width: $screen-md-min) {
        margin-top: -380px;
        transition: all 0.5s ease;

        &.modalContentOpen {
          margin-top: 0;
          transform: translateY(0);
        }
      }

      @media screen and (max-width: $screen-sm-max) {
        padding: 30px 20px;
        box-sizing: border-box;
        border-radius: 6px;
        width: 93%;
        flex-direction: column;
        height: 85%;
      }

      @media screen and (max-width: $screen-xs-max) {
        height: 100%;
        max-height: 508px;
      }

      .leftSectionContainer {
        width: 50%;
        height: 100%;
        padding: 29px 0;
        box-sizing: border-box;

        @media screen and (max-width: $screen-sm-max) {
          width: 100%;
        }

        .notificationsImage {
          background-size: cover;
          height: 100%;

          @media screen and (max-width: $screen-sm-max) {
            width: 100%;
            height: 108px;
          }
        }
      }

      .rightSectionContainer {
        max-width: 611px;

        @media screen and (max-width: $screen-md-max) {
          text-align: center;
          max-width: 450px;
        }

        .textContainer {
          .title {
            font-weight: 500;
            font-size: 28px;

            @media screen and (max-width: $screen-sm-max) {
              text-align: center;
            }
          }

          .description {
            margin-top: 15px;
            font-size: 14px;
            color: $charcoal_grey;
          }
        }

        .actionsContainer {
          margin-top: 15px;

          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media screen and (max-width: $screen-sm-max) {
            flex-direction: column-reverse;
          }

          .cancelLink {
            font-size: 16px;
            font-weight: 500;
            margin-right: 33px;
            cursor: pointer;

            @media screen and (max-width: $screen-sm-max) {
              margin-right: 0;
              margin-top: 28px;
            }
          }

          .enableBtn {
            height: 60px;
            font-size: 16px;

            @media screen and (max-width: $screen-sm-max) {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .dismissContainer {
      width: 100%;
      display: flex;
      justify-content: center;

      .dismissText {
        padding-top: 18px;
        color: white;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
