@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/_media_variables.scss';

.notificationBarContainer {
  top: 0;
  margin-top: 70px;
  position: fixed;
  background-color: #51a454;
  color: $white;
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  z-index: 3;

  &.warning {
    background-color: $red;
  }

  @media screen and (max-width: $screen-sm-max) {
    margin-top: 60px;
  }
}
.notificationBarContainer.hidden {
  display: none;
}

.content {
  flex-grow: 1;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: $screen-sm-max) {
    margin: auto 15px;
  }
}

.close {
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
}
