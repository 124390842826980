@import 'src/assets/styles/colors.scss';

.generalLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px 0;
}

.loaderImage {
  height: 2.5em;
  width: 2.5em;
  position: absolute;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 92px;
  height: 92px;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  border-width: 1.5em;
  border-style: solid;
  border-color: #0095d430 #0095d430 #0095d430 #0095d4;
  animation: load 1.5s infinite cubic-bezier(0.78, -0.58, 0.35, 1.02);
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
