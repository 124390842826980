@import 'src/assets/styles/_media_variables.scss';
@import 'src/assets/styles/colors.scss';

.filterBoxWrapper {
  padding: 0px 20px 0px 10px;
  background-color: white;
  margin: 5px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  border: 1px solid #c7c9cc;

  &.missingImage {
    padding: 0 10px;
    justify-content: center;
  }
}
.infoWrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333333;
}
.infoWrapper.selected {
  color: white;
}
.image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-repeat: no-repeat;
}
.filterBoxWrapper.selected {
  background-color: $primary_blue;
}
